import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  Image,
  PaginatedGrid,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"home-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
                background={true}
                forceChildren={true}
              />
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
            textAlign={"home-content"}
            centered={true}
          >
            <Grid.Column width={12} textAlign={"center"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Content.Image
                  width={7}
                  background={false}
                  src={
                    "https://fisherman.gumlet.io/public/benniespizzapub/b.jpg_gD3I0Rj.jpeg"
                  }
                />
                <Content.Markup
                  width={9}
                  isMarkdown={true}
                  content={fishermanBusiness.aboutMarkdown}
                />
              </Content>
            </Grid.Column>
            <Grid.Column width={16} textAlign={"center"}>
              <Grid
                stackable={true}
                className={"component-section-container"}
                verticalAlign={"middle"}
                textAlign={"center"}
              >
                <Grid.Column width={14} textAlign={"center"}>
                  <Grid
                    stackable={true}
                    className={"component-section-container custom-banner"}
                    verticalAlign={"top"}
                    textAlign={"center"}
                    columns={"equal"}
                  >
                    <Grid.Row streched={true}>
                      <Grid.Column>
                        <Image
                          size={"medium"}
                          centered={true}
                          className={"familly-special-picture"}
                          src={
                            "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/familydeal_81HEcpQ.jpg"
                          }
                        />
                        <h4>FEED THE FAMILY DEAL</h4>
                        <p>
                          The deal made to delight. Grab a Standard or Premium
                          16" with all the extras to make it a real meal.
                        </p>
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          size={"medium"}
                          centered={true}
                          src={
                            "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/Snapshot_-_Game_Room-min_1.jpg"
                          }
                        />
                        <h4>BENNIE'S GAME ROOM</h4>
                        <p>
                          We have a game room available for those who are 21+
                          and feeling lucky!
                        </p>
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          size={"medium"}
                          centered={true}
                          src={
                            "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/beer_taps_selection.jpg"
                          }
                        />
                        <h4>BEER SELECTION</h4>
                        <p>
                          We have a rotating line of beers on tap! Come on in to
                          see what seasonal sensations we have ready to quench
                          your thirst.
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
            <Grid.Column>
              <PaginatedGrid
                rows={2}
                columns={2}
                className={"reviews"}
                tight={true}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={200} />,
                  propMap: { author: "author", text: "text" },
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
